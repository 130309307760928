
import React from 'react';
import { ComposedChart, Bar, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';

const BAR_SIZE = 25
const CustomGroupedBarType = ({
    data,
    barKeys = { submitted: 'Applications Submitted', approved: 'Applications Approved' },
    lineKey = 'Approval Rate (%)',
    colors = { bar1: '#82ca9d', bar2: '#8884d8', line: '#ff7300' },
    leftYAxisLabel = "# of Applications",
    rightYAxisLabel = "% of Applications",
    leftYAxisDomain = [0, 'dataMax'],
    rightYAxisDomain = [0, 100],
}) => {

    const tempchartData = [];
    let totalValue1 = 0;

    // First, calculate the total sum of value1 for all entries
    data.forEach(item => {
        totalValue1 += item.value1;
    });

    // Now, iterate through the data to combine and add matching "head"
    data.forEach(item => {
        let existing = tempchartData.find(entry => entry.head === item.head);
        if (existing) {
            existing.value1 += item.value1; // Aggregate value1 if the "head" matches
        } else {
            tempchartData.push({
                head: item.head,
                value1: item.value1,
                value2: totalValue1, // Set value2 to the total sum of value1
                value3: ((item.value1 / totalValue1) * 100).toFixed(2) // Calculate value3 and round to 2 decimals
            });
        }
    });

    // Now update value3 for all entries
    tempchartData.forEach(item => {
        item.value3 = ((item.value1 / item.value2) * 100).toFixed(2); // Recalculate value3 correctly and round to 2 decimals
    });

    const chartData = tempchartData?.map(item => ({
        name: item.head,
        [barKeys.submitted]: item.value1,
        [barKeys.approved]: item.value2,
        [lineKey]: item.value3,
    }));

    // Custom Tooltip to display required information
    // const customTooltip = ({ payload, label }) => {
    //     if (payload && payload.length) {
    //         const data = payload[0].payload;
    //         return (
    //             <div style={{ backgroundColor: '#fff', padding: '10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
    //                 <p key={index} style={{ color: item.color }}>
    //                     <p><strong>Loan Type:</strong> {data.name}</p>
    //                 <p><strong># of Applications:</strong> {data[barKeys.submitted]}</p>
    //                 <p><strong>$ of Applications:</strong> {data[lineKey]}%</p>
    //             </div>
    //         );
    //     }
    //     return null;
    // };

    const customTooltip = ({ payload, label }) => {
        if (payload && payload.length) {
            const data = payload[0].payload;

            // Define a unique color for "Loan Type"
            const loanTypeColor = 'black'; // Example: orange-red color

            return (
                <div style={{ backgroundColor: '#fff', padding: '10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                    <p style={{ color: loanTypeColor }}>
                        <strong>Loan Type:</strong> {data.name}
                    </p>
                    <p style={{ color: payload.find(item => item.dataKey === barKeys.submitted)?.color }}>
                        <strong># of Applications:</strong> {data[barKeys.submitted]}
                    </p>
                    <p style={{ color: payload.find(item => item.dataKey === barKeys.submitted)?.color }}>
                        <strong>Total Applications:</strong> {data[barKeys.value2]}
                    </p>
                    <p style={{ color: payload.find(item => item.dataKey === lineKey)?.color }}>
                        <strong>% of Applications:</strong> {data[lineKey]}%
                    </p>
                </div>
            );
        }
        return null;
    };


    return (
        <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
                data={chartData}
                margin={{
                    top: 20,
                    right: 40,
                    bottom: 20,
                    left: 20,
                }}
            >
                {/* Cartesian Grid */}
                <CartesianGrid strokeDasharray="3 3" />

                {/* X-Axis */}
                <XAxis dataKey="name" />

                {/* Left Y-Axis for Bar Values */}
                <YAxis
                    yAxisId="left"
                    label={{
                        value: leftYAxisLabel,
                        angle: -90,
                        position: 'left',
                        offset: 10,
                        style: { fontWeight: 'bold', textAnchor: 'middle' }
                    }}
                    domain={leftYAxisDomain}
                />

                {/* Right Y-Axis for Line Values */}
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    tickFormatter={(value) => `${value}%`}
                    domain={rightYAxisDomain}
                    label={{
                        value: rightYAxisLabel,
                        angle: 90,
                        position: 'right',
                        offset: 10,
                        style: { fontWeight: 'bold', textAnchor: 'middle' }
                    }}
                />

                {/* Custom Tooltip */}
                <Tooltip content={customTooltip} />

                {/* Legend */}
                <Legend />

                {/* Bars with fixed width */}
                <Bar
                    yAxisId="left"
                    dataKey={barKeys.submitted}
                    fill={colors.bar1}
                    barSize={BAR_SIZE} // Set fixed bar width
                />
                <Bar
                    yAxisId="left"
                    dataKey={barKeys.approved}
                    fill={colors.bar2}
                    barSize={BAR_SIZE} // Set fixed bar width
                />

                {/* Line for Approval Rate */}
                <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey={lineKey}
                    stroke={colors.line}
                    strokeWidth={3}
                    dot={{ r: 6, fill: colors.line }}
                    activeDot={{ r: 8 }}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default CustomGroupedBarType;
