
// // import { Button } from '@mui/material';
// // import React from 'react';
// // import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// // const CustomBarChart = ({

// //     data,
// //     key1, key2, onBack }) => {


// //     key1 = "Total Number Of loans"
// //     key2 = "Total loan Amount"
// //     if (!data || !key1 || !key2) {
// //         return <div>Invalid data or keys provided</div>;
// //     }

// //     return (
// //         <div style={{ width: '100%', height: '400px' }}>
// //             <Button
// //                 variant="contained"
// //                 color="secondary"
// //                 style={{ marginTop: '20px' }}
// //                 onClick={onBack}
// //             >
// //                 Back
// //             </Button>
// //             <ResponsiveContainer width="100%" height="100%">
// //                 <BarChart
// //                     width={500}
// //                     height={300}
// //                     data={data}
// //                     margin={{
// //                         top: 20,
// //                         right: 30,
// //                         left: 20,
// //                         bottom: 5,
// //                     }}
// //                 >
// //                     <CartesianGrid strokeDasharray="3 3" />
// //                     <XAxis dataKey="name" />
// //                     <YAxis />
// //                     <Tooltip />
// //                     <Legend />

// //                     <Bar dataKey={key1} stackId="a" fill="#8884d8" />
// //                     <Bar dataKey={key2} stackId="a" fill="#82ca9d" />
// //                 </BarChart>
// //             </ResponsiveContainer>
// //         </div>
// //     );
// // };

// // export default CustomBarChart;
// import React from 'react';
// import { Button } from '@mui/material';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const CustomBarChart = ({
//     data,
//     xAxisKey = 'head',
//     yAxisLabel = '# of Leads',
//     barKey = 'value1', // Updated default barKey
//     barColor = '#8884d8',
//     onBack
// }) => {
//     console.log("CustomBarChart Raw Data:", data);

//     // Group data by 'head' and sum up 'value1'
//     const processedData = Object.values(
//         data.reduce((acc, item) => {
//             if (!acc[item[xAxisKey]]) {
//                 acc[item[xAxisKey]] = {
//                     [xAxisKey]: item[xAxisKey],
//                     [barKey]: 0,
//                 };
//             }
//             acc[item[xAxisKey]][barKey] += item[barKey] || 0;
//             return acc;
//         }, {})
//     );

//     console.log('Processed Data:', processedData);

//     if (!processedData || processedData.length === 0) {
//         return <div>No valid data available to display.</div>;
//     }

//     return (
//         <div style={{ width: '100%', height: '400px' }}>
//             <Button
//                 variant="contained"
//                 color="secondary"
//                 style={{ marginTop: '20px', marginBottom: '20px' }}
//                 onClick={onBack}
//             >
//                 Back
//             </Button>
//             <ResponsiveContainer width="100%" height="100%">
//                 <BarChart
//                     data={processedData}
//                     margin={{
//                         top: 20,
//                         right: 30,
//                         left: 20,
//                         bottom: 5,
//                     }}
//                 >
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis dataKey={xAxisKey} />
//                     <YAxis label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }} />
//                     <Tooltip />
//                     <Legend />
//                     <Bar dataKey={barKey} fill={barColor} barSize={25} />
//                 </BarChart>
//             </ResponsiveContainer>
//         </div>
//     );
// };

// export default CustomBarChart;
import React from 'react';
import { Button } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomBarChart = ({
    data,
    xAxisKey = 'head',        // Key for x-axis
    xAxisLabel = '# of Leads', // Label for x-axis (default set to "# of Leads")
    yAxisLabel = '# of Leads', // Label for y-axis (adjustable)
    barKey = 'value1',        // Key for bar data (to be used internally)
    barColor = '#8884d8',     // Bar color
    onBack
}) => {
    console.log("CustomBarChart Raw Data:", data);

    // Group data by 'xAxisKey' and sum up 'barKey'
    const processedData = Object.values(
        data.reduce((acc, item) => {
            if (!acc[item[xAxisKey]]) {
                acc[item[xAxisKey]] = {
                    [xAxisKey]: item[xAxisKey],
                    [barKey]: 0,
                };
            }
            acc[item[xAxisKey]][barKey] += item[barKey] || 0;
            return acc;
        }, {})
    );

    console.log('Processed Data:', processedData);

    if (!processedData || processedData.length === 0) {
        return <div>No valid data available to display.</div>;
    }

    return (
        <div style={{ width: '100%', height: '400px' }}>
            {/* <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: '20px', marginBottom: '20px' }}
                onClick={onBack}
            >
                Back
            </Button> */}
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={processedData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey={xAxisKey}
                    //label={{ value: xAxisLabel, position: 'insideBottom', offset: -5 }}
                    />
                    <YAxis
                        label={{
                            value: yAxisLabel,
                            angle: -90,
                            position: 'insideLeft',
                            style: { fontWeight: 'bold' } // Apply bold style to the label
                        }}
                    />

                    <Tooltip
                        formatter={(value) => `${value}`} // Show "# of Leads" in tooltip
                    />
                    <Legend />
                    <Bar
                        dataKey={barKey}
                        fill={barColor}
                        barSize={25}
                        name={xAxisLabel} // Set name to reflect "# of Leads"
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default CustomBarChart;
