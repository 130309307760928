import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const LoanCategoryBar = ({
    data,
    xAxisKey = 'head', // Key for x-axis
    xAxisLabel = 'Loan Terms', // Label for x-axis
    yAxisLabel = '# of Loans', // Label for y-axis
    barColors = ['#8884d8', '#82ca9d', '#ffc658'], // Colors for different categories
}) => {
    console.log("CustomBarChart Raw Data:", data);

    // Group data by loanCategory and head
    const groupedData = {};
    data.forEach((item) => {
        const { loanCategory, head, value1 } = item;
        if (!groupedData[head]) {
            groupedData[head] = { head };
        }
        groupedData[head][loanCategory] = (groupedData[head][loanCategory] || 0) + (value1 || 0);
    });

    // Convert grouped data into an array for the chart
    const processedData = Object.values(groupedData);

    console.log('Processed Data:', processedData);

    // Extract unique loan categories for dynamic bars
    const loanCategories = [...new Set(data.map((item) => item.loanCategory))];

    if (!processedData || processedData.length === 0) {
        return <div>No valid data available to display.</div>;
    }

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={processedData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={xAxisKey} label={{ value: xAxisLabel, position: 'insideBottom', offset: -5 }} />
                    <YAxis
                        label={{
                            value: yAxisLabel,
                            angle: -90,
                            position: 'insideLeft',
                            style: { fontWeight: 'bold' },
                        }}
                    />
                    <Tooltip />
                    <Legend />
                    {loanCategories.map((category, index) => (
                        <Bar
                            key={category}
                            dataKey={category}
                            fill={barColors[index % barColors.length]}
                            name={category}
                            barSize={25}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default LoanCategoryBar;