
import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
    Cell,
} from 'recharts';

const AvgLoanCycle = ({
    data,
    xAxisKey = 'loanCategory',
    xAxisLabel = '',
    yAxisLabel = '# of days',
    barColors = ['#8884d8', '#82ca9d', '#ffc658', '#d084d8', '#8dd1e1'],
}) => {
    console.log("CustomBarChart Raw Data:", data);


    const groupedData = {};
    data.forEach((item) => {
        const { loanCategory, dayDifference } = item;

        if (!groupedData[loanCategory]) {
            groupedData[loanCategory] = {
                loanCategory,
                totalDayDifference: 0,
                count: 0,
                head: 0,
            };
        }

        groupedData[loanCategory].totalDayDifference += dayDifference;
        groupedData[loanCategory].count += 1;
        groupedData[loanCategory].head = (
            groupedData[loanCategory].totalDayDifference /
            groupedData[loanCategory].count
        ).toFixed(2);
    });


    const processedData = Object.values(groupedData);

    console.log('Processed Data:', processedData);

    if (!processedData || processedData.length === 0) {
        return <div>No valid data available to display.</div>;
    }

    // Custom Tooltip
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { loanCategory, count, head } = payload[0].payload;
            return (
                <div
                    style={{
                        background: '#fff',
                        border: '1px solid #ccc',
                        padding: '10px',
                        borderRadius: '5px',
                        color: '#333',
                    }}
                >
                    <p style={{ margin: 0 }}>Category: {loanCategory}</p>
                    <p style={{ margin: 0 }}># of Loans: {count}</p>
                    <p style={{ margin: 0 }}>Avg Life Cycle Days: {head}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={processedData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey={xAxisKey}
                        label={{ value: xAxisLabel, position: 'bottom', offset: -5 }}
                    />
                    <YAxis
                        label={{
                            value: yAxisLabel,
                            angle: -90,
                            position: 'left',
                            style: { fontWeight: 'bold' },
                        }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar
                        dataKey="head"
                        name="Average Life Cycle of Mortgage Loans"
                        barSize={25}
                    >
                        {processedData.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={barColors[index % barColors.length]}
                            />
                        ))}
                        <LabelList
                            dataKey="count"
                            position="top"
                            style={{ fontSize: '12px', fontWeight: 'bold', fill: '#333' }}
                        />
                        {/* <LabelList
                            dataKey="count"
                            position="top"
                            style={{ fontSize: '12px', fontWeight: 'bold', fill: '#333' }}
                            formatter={(value) => `# of loans: ${value}`}
                        /> */}

                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default AvgLoanCycle;
