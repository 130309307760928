// import React, { useState } from 'react';
// import {
//     BarChart,
//     Bar,
//     XAxis,
//     YAxis,
//     CartesianGrid,
//     Tooltip,
//     Legend,
//     ResponsiveContainer,
// } from 'recharts';

// const TaskChart = ({ data }) => {
//     // Group data by loanYear and loanMonth to calculate averages
//     const groupedData = {};

//     const [totDocCount, setTotDocCount] = useState(null)

//     data.forEach((item) => {
//         const { loanYear, loanMonth, totalDocCount, totalTaskCount } = item;
//         setTotDocCount(totalDocCount)
//         const monthYear = `${loanYear}-${loanMonth}`;

//         if (!groupedData[monthYear]) {
//             groupedData[monthYear] = {
//                 monthYear,
//                 totalDocCount: 0,
//                 totalTaskCount: 0,
//                 count: 0,
//             };
//         }

//         groupedData[monthYear].totalDocCount += totalDocCount;
//         groupedData[monthYear].totalTaskCount += totalTaskCount;
//         groupedData[monthYear].count += 1;
//     });

//     // Process grouped data to calculate averages
//     const processedData = Object.values(groupedData).map((group) => ({
//         monthYear: group.monthYear,
//         avgDocCount: (group.totalDocCount / group.count).toFixed(2),
//         avgTaskCount: (group.totalTaskCount / group.count).toFixed(2),
//     }));

//     // Custom Tooltip for detailed information
//     // const CustomTooltip = ({ active, payload }) => {
//     //     if (active && payload && payload.length) {
//     //         const { monthYear, avgDocCount, avgTaskCount } = payload[0].payload;
//     //         return (
//     //             <div
//     //                 style={{
//     //                     background: '#fff',
//     //                     border: '1px solid #ccc',
//     //                     padding: '10px',
//     //                     borderRadius: '5px',
//     //                     color: '#333',
//     //                 }}
//     //             >
//     //                 <p style={{ margin: 0 }}>Month-Year: {monthYear}</p>
//     //                 <p style={{ margin: 0 }}>Avg Doc Count: {avgDocCount}</p>
//     //                 <p style={{ margin: 0 }}>Avg Task Count: {avgTaskCount}</p>
//     //             </div>
//     //         );
//     //     }
//     //     return null;
//     // };

//     const CustomTooltip = ({ active, payload }) => {
//         if (active && payload && payload.length) {
//             const { monthYear, avgDocCount, avgTaskCount, count } = payload[0].payload;
//             return (
//                 <div
//                     style={{
//                         background: '#fff',
//                         border: '1px solid #ccc',
//                         padding: '10px',
//                         borderRadius: '5px',
//                         color: '#333',
//                     }}
//                 >
//                     <p style={{ margin: 0 }}>Month-Year: {monthYear}</p>
//                     <p style={{ margin: 0 }}># of Doc Count: {totalDocCount} </p>
//                     <p style={{ margin: 0 }}># of Loans: {count} </p>
//                     <p style={{ margin: 0 }}>Avg Doc Count: {avgDocCount} </p>
//                     <p style={{ margin: 0 }}># of Task Count: {totalTaskCount} </p>
//                     <p style={{ margin: 0 }}># of Loans: {count} </p>
//                     <p style={{ margin: 0 }}>Avg Task Count: {avgTaskCount} </p>
//                 </div>
//             );
//         }
//         return null;
//     };


//     return (
//         <div style={{ width: '100%', height: '400px' }}>
//             <ResponsiveContainer width="100%" height="100%">
//                 <BarChart
//                     data={processedData}
//                     margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
//                 >
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis
//                         dataKey="monthYear"
//                         label={{ value: '', position: 'bottom', offset: -5 }}
//                     />
//                     <YAxis
//                         label={{
//                             value: '# of Conditions',
//                             angle: -90,
//                             position: 'left',
//                             style: { fontWeight: 'bold' },
//                         }}
//                     />
//                     <Tooltip content={<CustomTooltip />} />
//                     <Legend />
//                     <Bar dataKey="avgDocCount" fill="#8884d8" name="Avg Doc Count" barSize={25} />
//                     <Bar dataKey="avgTaskCount" fill="#82ca9d" name="Avg Task Count" barSize={25} />
//                 </BarChart>
//             </ResponsiveContainer>
//         </div>
//     );
// };

// export default TaskChart;
import React, { useState, useEffect } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const TaskChart = ({ data }) => {
    const [totDocCount, setTotDocCount] = useState(0);
    const [totTaskCount, setTotTaskCount] = useState(0);
    const [totalLoans, setTotalLoans] = useState(0);
    const [processedData, setProcessedData] = useState([]);

    useEffect(() => {
        const groupedData = {};
        let totalDocCount = 0;
        let totalTaskCount = 0;
        let loanCount = 0;

        data.forEach((item) => {
            const { loanYear, loanMonth, totalDocCount: docCount, totalTaskCount: taskCount } = item;
            const monthYear = `${loanYear}-${loanMonth}`;

            if (!groupedData[monthYear]) {
                groupedData[monthYear] = {
                    monthYear,
                    totalDocCount: 0,
                    totalTaskCount: 0,
                    loans: 0,
                };
            }

            groupedData[monthYear].totalDocCount += docCount;
            groupedData[monthYear].totalTaskCount += taskCount;
            groupedData[monthYear].loans += 1;

            totalDocCount += docCount;
            totalTaskCount += taskCount;
            loanCount += 1;
        });

        setTotDocCount(totalDocCount);
        setTotTaskCount(totalTaskCount);
        setTotalLoans(loanCount);

        const processed = Object.values(groupedData).map((group) => ({
            monthYear: group.monthYear,
            avgDocCount: (group.totalDocCount / group.loans).toFixed(2),
            avgTaskCount: (group.totalTaskCount / group.loans).toFixed(2),
            totalDocCount: group.totalDocCount,
            totalTaskCount: group.totalTaskCount,
            loans: group.loans,
        }));

        setProcessedData(processed);
    }, [data]);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { monthYear, avgDocCount, avgTaskCount, totalDocCount, totalTaskCount, loans } =
                payload[0].payload;
            return (
                <div
                    style={{
                        background: '#fff',
                        border: '1px solid #ccc',
                        padding: '10px',
                        borderRadius: '5px',
                        color: '#333',
                    }}
                >
                    <p style={{ margin: 0 }}>Month-Year: {monthYear}</p>
                    <p style={{ margin: 0 }}>Total Doc Count: {totalDocCount}</p>
                    <p style={{ margin: 0 }}>Total Task Count: {totalTaskCount}</p>
                    <p style={{ margin: 0 }}>Number of Loans: {loans}</p>
                    <p style={{ margin: 0 }}>Avg Doc Count: {avgDocCount}</p>
                    <p style={{ margin: 0 }}>Avg Task Count: {avgTaskCount}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <div style={{ width: '100%', height: '400px' }}>

            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={processedData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="monthYear"
                        label={{ value: '', position: 'bottom', offset: -5 }}
                    />
                    <YAxis
                        label={{
                            value: '# of Conditions',
                            angle: -90,
                            position: 'left',
                            style: { fontWeight: 'bold' },
                        }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar dataKey="avgDocCount" fill="#8884d8" name="Avg Doc Count" barSize={25} />
                    <Bar dataKey="avgTaskCount" fill="#82ca9d" name="Avg Task Count" barSize={25} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default TaskChart;
