// // // // // Chart3.js
// // // // import { Button } from '@mui/material';
// // // // import React from 'react';
// // // // import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// // // // const data = [
// // // //     { name: 'Group A', value: 400 },
// // // //     { name: 'Group B', value: 300 },
// // // //     { name: 'Group C', value: 300 },
// // // //     { name: 'Group D', value: 200 },
// // // // ];

// // // // const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// // // // const Chart3 = ({ onBack }) => {
// // // //     return (
// // // //         <ResponsiveContainer width="100%" height={400}>
// // // //             <Button
// // // //                 variant="contained"
// // // //                 color="secondary"
// // // //                 style={{ marginTop: '20px' }}
// // // //                 onClick={onBack}
// // // //             >
// // // //                 Back
// // // //             </Button>
// // // //             <PieChart>
// // // //                 <Pie data={data} dataKey="value" outerRadius={150} fill="#8884d8" label>
// // // //                     {data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
// // // //                 </Pie>
// // // //                 <Tooltip />
// // // //                 <Legend />
// // // //             </PieChart>
// // // //         </ResponsiveContainer>
// // // //     );
// // // // };

// // // // export default Chart3;






// // // // Date Range Picker Example


// // // import React, { useState } from 'react';
// // // import { Button, Box, TextField } from '@mui/material';
// // // import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// // // import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// // // import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// // // import dayjs from 'dayjs';

// // // const initialData = [
// // //     { name: 'Group A', value: 400, date: '2024-01-01' },
// // //     { name: 'Group B', value: 300, date: '2024-02-01' },
// // //     { name: 'Group C', value: 300, date: '2024-03-01' },
// // //     { name: 'Group D', value: 200, date: '2024-04-01' },
// // // ];

// // // const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// // // const Chart3 = ({ onBack }) => {
// // //     const [startDate, setStartDate] = useState(null);
// // //     const [endDate, setEndDate] = useState(null);
// // //     const [filteredData, setFilteredData] = useState(initialData);

// // //     // Filter data when dates change
// // //     const handleDateChange = () => {
// // //         if (startDate && endDate) {
// // //             const filtered = initialData.filter((item) => {
// // //                 const itemDate = dayjs(item.date);
// // //                 return itemDate.isAfter(dayjs(startDate)) && itemDate.isBefore(dayjs(endDate));
// // //             });
// // //             setFilteredData(filtered);
// // //         } else {
// // //             setFilteredData(initialData); // Reset if dates are not fully selected
// // //         }
// // //     };

// // //     return (
// // //         <LocalizationProvider dateAdapter={AdapterDayjs}>
// // //             <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
// // //                 <Box display="flex" gap={2} marginBottom={3}>
// // //                     <DatePicker
// // //                         label="Start Date"
// // //                         value={startDate}
// // //                         onChange={(newDate) => {
// // //                             setStartDate(newDate);
// // //                             handleDateChange();
// // //                         }}
// // //                         renderInput={(params) => <TextField {...params} />}
// // //                     />
// // //                     <DatePicker
// // //                         label="End Date"
// // //                         value={endDate}
// // //                         onChange={(newDate) => {
// // //                             setEndDate(newDate);
// // //                             handleDateChange();
// // //                         }}
// // //                         renderInput={(params) => <TextField {...params} />}
// // //                     />
// // //                 </Box>

// // //                 <ResponsiveContainer width="100%" height={400}>
// // //                     <PieChart>
// // //                         <Pie data={filteredData} dataKey="value" outerRadius={150} fill="#8884d8" label>
// // //                             {filteredData.map((entry, index) => (
// // //                                 <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
// // //                             ))}
// // //                         </Pie>
// // //                         <Tooltip />
// // //                         <Legend />
// // //                     </PieChart>
// // //                 </ResponsiveContainer>

// // //                 <Button
// // //                     variant="contained"
// // //                     color="secondary"
// // //                     style={{ marginTop: '20px' }}
// // //                     onClick={onBack}
// // //                 >
// // //                     Back
// // //                 </Button>
// // //             </Box>
// // //         </LocalizationProvider>
// // //     );
// // // };

// // // export default Chart3;



// // import React, { useState } from 'react';
// // import { Button, Box, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
// // import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// // import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// // import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// // import dayjs from 'dayjs';

// // const initialData = [
// //     { name: 'Group A', value: 400, date: '2024-01-01' },
// //     { name: 'Group B', value: 300, date: '2024-02-01' },
// //     { name: 'Group C', value: 300, date: '2024-03-01' },
// //     { name: 'Group D', value: 200, date: '2024-04-01' },
// // ];

// // const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// // const Chart3 = ({ onBack }) => {
// //     const [filterMode, setFilterMode] = useState('range'); // 'range' or 'single'
// //     const [startDate, setStartDate] = useState(null);
// //     const [endDate, setEndDate] = useState(null);
// //     const [singleDate, setSingleDate] = useState(null);
// //     const [filteredData, setFilteredData] = useState(initialData);

// //     // Update data filtering logic
// //     const handleFilterChange = () => {
// //         if (filterMode === 'range' && startDate && endDate) {
// //             const filtered = initialData.filter((item) => {
// //                 const itemDate = dayjs(item.date);
// //                 return itemDate.isAfter(dayjs(startDate)) && itemDate.isBefore(dayjs(endDate));
// //             });
// //             setFilteredData(filtered);
// //         } else if (filterMode === 'single' && singleDate) {
// //             const filtered = initialData.filter((item) =>
// //                 dayjs(item.date).isSame(dayjs(singleDate), 'day')
// //             );
// //             setFilteredData(filtered);
// //         } else {
// //             setFilteredData(initialData); // Reset if no filters are selected
// //         }
// //     };

// //     return (
// //         <LocalizationProvider dateAdapter={AdapterDayjs}>
// //             <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
// //                 {/* Toggle for filter mode */}
// //                 <ToggleButtonGroup
// //                     value={filterMode}
// //                     exclusive
// //                     onChange={(event, newMode) => setFilterMode(newMode || 'range')}
// //                     style={{ marginBottom: '20px' }}
// //                 >
// //                     <ToggleButton value="range">Date Range</ToggleButton>
// //                     <ToggleButton value="single">Single Date</ToggleButton>
// //                 </ToggleButtonGroup>

// //                 {/* Date Picker Inputs */}
// //                 {filterMode === 'range' ? (
// //                     <Box display="flex" gap={2} marginBottom={3}>
// //                         <DatePicker
// //                             label="Start Date"
// //                             value={startDate}
// //                             onChange={(newDate) => {
// //                                 setStartDate(newDate);
// //                                 handleFilterChange();
// //                             }}
// //                             renderInput={(params) => <TextField {...params} />}
// //                         />
// //                         <DatePicker
// //                             label="End Date"
// //                             value={endDate}
// //                             onChange={(newDate) => {
// //                                 setEndDate(newDate);
// //                                 handleFilterChange();
// //                             }}
// //                             renderInput={(params) => <TextField {...params} />}
// //                         />
// //                     </Box>
// //                 ) : (
// //                     <Box marginBottom={3}>
// //                         <DatePicker
// //                             label="Select Date"
// //                             value={singleDate}
// //                             onChange={(newDate) => {
// //                                 setSingleDate(newDate);
// //                                 handleFilterChange();
// //                             }}
// //                             renderInput={(params) => <TextField {...params} />}
// //                         />
// //                     </Box>
// //                 )}

// //                 {/* Chart */}
// //                 <ResponsiveContainer width="100%" height={400}>
// //                     <PieChart>
// //                         <Pie data={filteredData} dataKey="value" outerRadius={150} fill="#8884d8" label>
// //                             {filteredData.map((entry, index) => (
// //                                 <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
// //                             ))}
// //                         </Pie>
// //                         <Tooltip />
// //                         <Legend />
// //                     </PieChart>
// //                 </ResponsiveContainer>

// //                 {/* Back Button */}
// //                 <Button
// //                     variant="contained"
// //                     color="secondary"
// //                     style={{ marginTop: '20px' }}
// //                     onClick={onBack}
// //                 >
// //                     Back
// //                 </Button>
// //             </Box>
// //         </LocalizationProvider>
// //     );
// // };

// // export default Chart3;
// import React, { useState } from 'react';
// import { Button, Box, TextField, Typography, FormControlLabel, Switch } from '@mui/material';
// import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';

// const initialData = [
//     { name: 'Group A', value: 400, date: '2024-01-01' },
//     { name: 'Group B', value: 300, date: '2024-02-01' },
//     { name: 'Group C', value: 300, date: '2024-03-01' },
//     { name: 'Group D', value: 200, date: '2024-04-01' },
// ];

// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// const Chart3 = ({ onBack }) => {
//     const [isRange, setIsRange] = useState(false); // Toggle state for Range or Single mode
//     const [startDate, setStartDate] = useState(null);
//     const [endDate, setEndDate] = useState(null);
//     const [singleDate, setSingleDate] = useState(null);
//     const [filteredData, setFilteredData] = useState(initialData);

//     // Update data filtering logic
//     const handleFilterChange = () => {
//         if (isRange && startDate && endDate) {
//             const filtered = initialData.filter((item) => {
//                 const itemDate = dayjs(item.date);
//                 return itemDate.isAfter(dayjs(startDate)) && itemDate.isBefore(dayjs(endDate));
//             });
//             setFilteredData(filtered);
//         } else if (!isRange && singleDate) {
//             const filtered = initialData.filter((item) =>
//                 dayjs(item.date).isSame(dayjs(singleDate), 'day')
//             );
//             setFilteredData(filtered);
//         } else {
//             setFilteredData(initialData); // Reset if no filters are selected
//         }
//     };

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
//                 {/* Switch for mode toggle
//                 <Box display="flex" alignItems="center" marginBottom={3}>
//                     <Typography variant="subtitle1" style={{ marginRight: '8px' }}>
//                         Single
//                     </Typography>
//                     <FormControlLabel
//                         control={
//                             <Switch
//                                 checked={isRange}
//                                 onChange={(event) => {
//                                     setIsRange(event.target.checked);
//                                     setFilteredData(initialData); // Reset data on toggle
//                                 }}
//                                 color="primary"
//                             />
//                         }
//                         label="Range"
//                         labelPlacement="end"
//                     />
//                 </Box>


//                 {isRange ? (
//                     <Box display="flex" gap={2} marginBottom={3}>
//                         <DatePicker
//                             label="Start Date"
//                             value={startDate}
//                             onChange={(newDate) => {
//                                 setStartDate(newDate);
//                                 handleFilterChange();
//                             }}
//                             renderInput={(params) => <TextField {...params} />}
//                         />
//                         <DatePicker
//                             label="End Date"
//                             value={endDate}
//                             onChange={(newDate) => {
//                                 setEndDate(newDate);
//                                 handleFilterChange();
//                             }}
//                             renderInput={(params) => <TextField {...params} />}
//                         />
//                     </Box>
//                 ) : (
//                     <Box marginBottom={3}>
//                         <DatePicker
//                             label="Select Date"
//                             value={singleDate}
//                             onChange={(newDate) => {
//                                 setSingleDate(newDate);
//                                 handleFilterChange();
//                             }}
//                             renderInput={(params) => <TextField {...params} />}
//                         />
//                     </Box>
//                 )} */}

//                 {/* Toggle and Date Picker in the same row */}
//                 <Box display="flex" alignItems="center" gap={2} marginBottom={3}>

//                     {/* Date Picker Inputs */}
//                     {isRange ? (
//                         <Box display="flex" gap={2}>
//                             <DatePicker
//                                 label="Start Date"
//                                 value={startDate}
//                                 onChange={(newDate) => {
//                                     setStartDate(newDate);
//                                     handleFilterChange();
//                                 }}
//                                 renderInput={(params) => <TextField {...params} />}
//                             />
//                             <DatePicker
//                                 label="End Date"
//                                 value={endDate}
//                                 onChange={(newDate) => {
//                                     setEndDate(newDate);
//                                     handleFilterChange();
//                                 }}
//                                 renderInput={(params) => <TextField {...params} />}
//                             />
//                         </Box>
//                     ) : (
//                         <Box>
//                             <DatePicker
//                                 label="Select Date"
//                                 value={singleDate}
//                                 onChange={(newDate) => {
//                                     setSingleDate(newDate);
//                                     handleFilterChange();
//                                 }}
//                                 renderInput={(params) => <TextField {...params} />}
//                             />
//                         </Box>
//                     )}
//                     <Box display="flex" alignItems="center">
//                         <Typography variant="subtitle1" style={{ marginRight: '8px' }}>
//                             Single
//                         </Typography>
//                         <FormControlLabel
//                             control={
//                                 <Switch
//                                     checked={isRange}
//                                     onChange={(event) => {
//                                         setIsRange(event.target.checked);
//                                         setFilteredData(initialData); // Reset data on toggle
//                                     }}
//                                     color="primary"
//                                 />
//                             }
//                             label="Range"
//                             labelPlacement="end"
//                         />
//                     </Box>

//                 </Box>


//                 {/* Chart */}
//                 <ResponsiveContainer width="100%" height={400}>
//                     <PieChart>
//                         <Pie data={filteredData} dataKey="value" outerRadius={150} fill="#8884d8" label>
//                             {filteredData.map((entry, index) => (
//                                 <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                             ))}
//                         </Pie>
//                         <Tooltip />
//                         <Legend />
//                     </PieChart>
//                 </ResponsiveContainer>

//                 {/* Back Button */}
//                 <Button
//                     variant="contained"
//                     color="secondary"
//                     style={{ marginTop: '20px' }}
//                     onClick={onBack}
//                 >
//                     Back
//                 </Button>
//             </Box>
//         </LocalizationProvider>
//     );
// };

// // export default Chart3;
// import React, { useState } from 'react';
// import { Button, Box, TextField, Typography, FormControlLabel, Switch } from '@mui/material';
// import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';

// const initialData = [
//     { name: 'Group A', value: 400, date: '2024-01-01' },
//     { name: 'Group B', value: 300, date: '2024-02-01' },
//     { name: 'Group C', value: 300, date: '2024-03-01' },
//     { name: 'Group D', value: 200, date: '2024-04-01' },
// ];

// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// const Chart3 = ({ onBack }) => {
//     const [isRange, setIsRange] = useState(false); // Toggle state for Range or Single mode
//     const [selectedDate, setSelectedDate] = useState(null);
//     const [startDate, setStartDate] = useState(null);  // For range mode (start date)
//     const [endDate, setEndDate] = useState(null);      // For range mode (end date)
//     const [filteredData, setFilteredData] = useState(initialData);

//     // Handle date range and single date selection
//     const handleFilterChange = () => {
//         if (isRange && startDate && endDate) {
//             const filtered = initialData.filter((item) => {
//                 const itemDate = dayjs(item.date);
//                 return itemDate.isBetween(dayjs(startDate), dayjs(endDate), null, '[]');
//             });
//             setFilteredData(filtered);
//         } else if (!isRange && selectedDate) {
//             const filtered = initialData.filter((item) =>
//                 dayjs(item.date).isSame(dayjs(selectedDate), 'day')
//             );
//             setFilteredData(filtered);
//         } else {
//             setFilteredData(initialData); // Reset if no filters are selected
//         }
//     };

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
//                 {/* Switch for toggle */}
//                 <Box display="flex" alignItems="center" gap={2} marginBottom={3}>
//                     <Box display="flex" alignItems="center">
//                         <Typography variant="subtitle1" style={{ marginRight: '8px' }}>
//                             Single
//                         </Typography>
//                         <FormControlLabel
//                             control={
//                                 <Switch
//                                     checked={isRange}
//                                     onChange={(event) => {
//                                         setIsRange(event.target.checked);
//                                         setStartDate(null);
//                                         setEndDate(null);
//                                         setSelectedDate(null); // Reset dates
//                                         setFilteredData(initialData); // Reset data
//                                     }}
//                                     color="primary"
//                                 />
//                             }
//                             label="Range"
//                             labelPlacement="end"
//                         />
//                     </Box>

//                     {/* Single Calendar Picker (will work for both range and single date) */}
//                     <Box>
//                         <DatePicker
//                             label={isRange ? 'Select Date Range' : 'Select Date'}
//                             value={isRange ? startDate || endDate : selectedDate}  // Display either range or single date
//                             onChange={(newDate) => {
//                                 if (isRange) {
//                                     // If in range mode, select start or end date
//                                     if (!startDate) {
//                                         setStartDate(newDate);
//                                     } else {
//                                         setEndDate(newDate);
//                                     }
//                                 } else {
//                                     // If in single date mode, just set the selected date
//                                     setSelectedDate(newDate);
//                                 }
//                                 handleFilterChange(); // Trigger filter change based on the selection
//                             }}
//                             renderInput={(params) => <TextField {...params} />}
//                         />
//                     </Box>
//                 </Box>

//                 {/* Chart */}
//                 <ResponsiveContainer width="100%" height={400}>
//                     <PieChart>
//                         <Pie data={filteredData} dataKey="value" outerRadius={150} fill="#8884d8" label>
//                             {filteredData.map((entry, index) => (
//                                 <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                             ))}
//                         </Pie>
//                         <Tooltip />
//                         <Legend />
//                     </PieChart>
//                 </ResponsiveContainer>

//                 {/* Back Button */}
//                 <Button
//                     variant="contained"
//                     color="secondary"
//                     style={{ marginTop: '20px' }}
//                     onClick={onBack}
//                 >
//                     Back
//                 </Button>
//             </Box>
//         </LocalizationProvider>
//     );
// };

// export default Chart3;
import React, { useEffect, useState } from 'react';
import { Button, Box, TextField, Typography, FormControlLabel, Switch } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getApiCallDynamic } from '../../../services/api-service';
import { API } from '../../../services/api-config';



const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Chart3 = ({ onBack, data }) => {



    // const initialData = [
    //     { name: 'Group A', value: 2.5, date: '2024-01-01' },
    //     { name: 'Group B', value: 4.0, date: '2024-02-01' },
    //     { name: 'Group C', value: 3.5, date: '2024-03-01' },
    //     { name: 'Group D', value: 1, date: '2024-04-01' },
    // ];

    console.log("Fetch data " + JSON.stringify(data, null, 2));
    const initialData = data?.map(item => ({
        name: item.head,
        value: parseFloat(item?.value.toFixed(2))
    }));







    const [isRange, setIsRange] = useState(false); // Toggle state for Range or Single mode
    const [selectedDate, setSelectedDate] = useState(null);
    const [startDate, setStartDate] = useState(null);  // For range mode (start date)
    const [endDate, setEndDate] = useState(null);      // For range mode (end date)
    const [filteredData, setFilteredData] = useState(initialData);

    // Handle date range and single date selection
    const handleFilterChange = () => {
        if (isRange && startDate && endDate) {
            const filtered = initialData.filter((item) => {
                const itemDate = dayjs(item.date);
                return itemDate.isBetween(dayjs(startDate), dayjs(endDate), null, '[]');
            });
            setFilteredData(filtered);
        } else if (!isRange && selectedDate) {
            const filtered = initialData.filter((item) =>
                dayjs(item.date).isSame(dayjs(selectedDate), 'day')
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(initialData); // Reset if no filters are selected
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
                {/* Switch for toggle */}
                {/* <Box display="flex" alignItems="center" gap={2} marginBottom={3}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" style={{ marginRight: '8px' }}>
                            Single
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isRange}
                                    onChange={(event) => {
                                        setIsRange(event.target.checked);
                                        setStartDate(null);
                                        setEndDate(null);
                                        setSelectedDate(null); // Reset dates
                                        setFilteredData(initialData); // Reset data
                                    }}
                                    color="primary"
                                />
                            }
                            label="Range"
                            labelPlacement="end"
                        />
                    </Box>

                   
                    <Box>
                        {isRange ? (
                            // Two DatePickers for Range mode
                            <Box display="flex" gap={2}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newDate) => {
                                        setStartDate(newDate);
                                        handleFilterChange();
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newDate) => {
                                        setEndDate(newDate);
                                        handleFilterChange();
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Box>
                        ) : (
                            // Single Date Picker
                            <DatePicker
                                label="Select Date"
                                value={selectedDate}
                                onChange={(newDate) => {
                                    setSelectedDate(newDate);
                                    handleFilterChange();
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        )}
                    </Box>
                </Box> */}

                {/* Pie Chart */}
                <ResponsiveContainer width="100%" height={400}>
                    <PieChart>
                        <Pie data={filteredData} dataKey="value" outerRadius={150} fill="#8884d8" label>
                            {filteredData?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>

                {/* Back Button */}
                {/* <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginTop: '20px' }}
                    onClick={onBack}
                >
                    Back
                </Button> */}
            </Box>
        </LocalizationProvider>
    );
};

export default Chart3;
