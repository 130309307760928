
import React, { useState, useEffect } from 'react';
import { Box, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CustomDateRangePicker = ({ onDateChange }) => {
    const [isRange, setIsRange] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    useEffect(() => {
        if (isRange && startDate && endDate) {
            onDateChange({ type: 'dateRange', startDate, endDate });
        } else if (!isRange && selectedDate) {
            onDateChange({ type: 'singleDate', selectedDate });
        }
    }, [isRange, startDate, endDate, selectedDate, onDateChange]);

    return (
        <Box display="flex" alignItems="center" gap={2} marginBottom={3}>
            <Box display="flex" alignItems="center">
                <Typography variant="subtitle1" style={{ marginRight: '8px' }}>
                    Single
                </Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isRange}
                            onChange={(event) => {
                                setIsRange(event.target.checked);
                                setStartDate(null);
                                setEndDate(null);
                                setSelectedDate(null);
                            }}
                            color="primary"
                        />
                    }
                    label="Range"
                    labelPlacement="end"
                />
            </Box>


            <Box>
                {isRange ? (

                    <Box display="flex" gap={2}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newDate) => {
                                setStartDate(newDate);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newDate) => {
                                setEndDate(newDate);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                ) : (

                    <DatePicker
                        label="Select Date"
                        value={selectedDate}
                        onChange={(newDate) => {
                            setSelectedDate(newDate);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                )}
            </Box>
        </Box>
    );
};

export default CustomDateRangePicker;
