
import React from 'react';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';

const DownloadChartWrapper = ({ chartComponent, data, fileName = 'chart_data' }) => {
    const downloadPDF = () => {
        const doc = new jsPDF();
        doc.text('Chart Data', 10, 10); // Add title to the PDF
        doc.autoTable({
            head: [Object.keys(data[0])],
            body: data.map(item => Object.values(item)),
        });
        doc.save(`${fileName}.pdf`);
    };

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    };

    return (
        <div style={{ width: '100%' }}>
            {/* Render the chart */}
            {chartComponent}

            {/* Buttons */}
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
                {/* <button onClick={downloadPDF} style={buttonStyle}>
                    Download PDF
                </button> */}
                <Button onClick={downloadExcel} variant="contained">
                    Download Excel
                </Button>
            </div>
        </div>
    );
};

const buttonStyle = {
    padding: '10px 15px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '0 5px',
};

export default DownloadChartWrapper;
