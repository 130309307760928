
import React from 'react';
import {
    ResponsiveContainer,
    ComposedChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    LabelList,
} from 'recharts';

const CustomGroupedBarChartWithFilter = ({
    data,
    barSize = 25,
    colors = {
        CONVENTIONAL: '#82ca9d', // Green
        FHA: '#0088FE',          // Blue
        VA: '#FFBB28',           // Yellow
        USDA: '#FF8042',         // Orange
        OTHER: '#A28CC0',        // Purple
    },
}) => {
    const loanCategories = Array.from(new Set(data?.map((item) => item.loanCategory)));
    const monthYearCombinations = Array.from(
        new Set(data?.map((item) => `${item.loanMonth}/${item.loanYear}`))
    );

    const chartData = monthYearCombinations.map((monthYear) => {
        const [loanMonth, loanYear] = monthYear.split('/');
        const entry = {
            name: `${new Date(loanYear, loanMonth - 1).toLocaleDateString('en-US', { month: 'short', year: '2-digit' })}`
        };

        loanCategories.forEach((category) => {
            const filteredData = data.filter(
                (item) =>
                    item.loanMonth === loanMonth &&
                    item.loanYear === loanYear &&
                    item.loanCategory === category
            );

            const totalAmount = filteredData.reduce((sum, item) => sum + item.avgLoanAmount, 0);
            const loanCount = filteredData.length;

            entry[category] = totalAmount / 1000 / loanCount; // Loan amount in $k
            entry[`${category}_count`] = loanCount; // Loan count
        });

        return entry; c
    });

    // Custom Tooltip
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                    <p><strong>{payload[0].payload.name}</strong></p>
                    {payload
                        .filter((item) => item.payload[`${item.dataKey}_count`] > 0) // Exclude categories with 0 count
                        .map((item, index) => {
                            const loanCount = item.payload[`${item.dataKey}_count`];
                            return (
                                <p key={index} style={{ color: item.color }}>
                                    {`${item.dataKey}: $${item.value.toFixed(0).toLocaleString()}K, ${loanCount}`}
                                </p>

                            );
                        })}
                </div>
            );
        }
        return null;
    };


    const renderCustomLabel = ({ x, y, value }) => {
        if (value > 0) {
            return (
                <text
                    x={x}
                    y={y - 5}
                    fill="#000"
                    textAnchor="middle"
                    fontSize={12}
                    fontWeight="bold"
                >
                    {value}
                </text>
            );
        }
        return null;
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
                data={chartData}
                margin={{
                    top: 20,
                    right: 40,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                    yAxisId="left"
                    label={{
                        value: 'Avg Loan Value (in $ Thousands)',
                        angle: -90,
                        position: 'left',
                        style: { textAnchor: 'middle', fontWeight: 'bold' },
                    }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend />

                {loanCategories.map((category) => {
                    const fillColor = colors[category?.toUpperCase()] || '#cccccc';
                    return (
                        <Bar
                            key={category}
                            yAxisId="left"
                            dataKey={category}
                            fill={fillColor}
                            barSize={barSize}
                            name={category}
                        >

                            <LabelList
                                dataKey={`${category}_count`}
                                content={({ x, y, width, value }) => {
                                    if (value > 0) {
                                        return (
                                            <text
                                                x={x + width / 2}
                                                y={y - 5}
                                                fill="#000"
                                                textAnchor="middle"
                                                fontSize={12}
                                                fontWeight="bold"
                                            >
                                                {value}
                                            </text>
                                        );
                                    }
                                    return null;
                                }}
                            />

                        </Bar>
                    );
                })}
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default CustomGroupedBarChartWithFilter;
