



import React, { useEffect, useState } from 'react';
//import { Box, Button, IconButton, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomDateRangePicker from './CustomDateRangePicker';
import Chart1 from './../charts/Chart1';
import Chart2 from './../charts/Chart2';
import Chart3 from './../charts/Chart3';
import CustomBarChart from './../charts/CustomBarChart';
import CustomPieChart from './../charts/CustomPieChart';
import CustomStackedBarChart from './../charts/CustomStackedBarChart';
import { KSBackArrowIcon } from '../../../icons/custome-icon';

import { enUS } from 'date-fns/locale';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import CustomGroupedBarChart from './CustomGroupedBarChart';
import CustomGroupedBarType from './CustomGroupedBarType';
import CustomGroupedBarChartWithFilter from './CustomGroupedBarChartWithFilter'
import { Box, Button, Grid, IconButton, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { profileState } from '../../../recoil/profile-atom';
import { getApiCallDynamic } from '../../../services/api-service';
import { API } from '../../../services/api-config';
import { CONTACT_STATE, TERM_OF_LOAN, TYPES_OF_LOAN } from '../../../constants/constatnt-variable';
import DownloadChartWrapper from '../DownloadChartWrapper';
import CustomGroupedMultipleBarChart from './CustomGroupedMultipleBarChart';
import CustomGroupedBarChartForLeads from './CustomGroupedBarChartForLeads';
import LoanTermBar from './LoanTermBar';
import LoanCategoryBar from './LoanCategoryBar';
import AvgLoanCycle from './AvgLonCycle';
import TaskChart from './TaskChart';






export const ChartContainer = ({ activeComponent, onBack, selectedUserFromAdmin, accessFromAdmin = false }) => {
    const param = { userCode: null };
    const [additionalProps, setAdditionalProps] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [brokerProfileUserCode, setBrokerProfileUserCode] = useState(null);

    // Map each activeComponent to its corresponding API endpoint
    const apiMapping = {
        'Monthly Loan Product Analysis': API.getReportLoanMiles,
        'Loan Term': API.getReportLoanTerm,
        'Loan Category': API.getReportLoanType,
        'Loan Category/Loan Term': API.getReportLoanTerm,
        'Loan Term / Loan Category': API.getReportLoanTerm,
        'Application Approval Rate': API.getReportAAR,
        'Mortgage Loan Declined Rate': API.getReportLoanDeclinedRate,
        'Incomplete Loan Application Rate': API.getIncompleteLoanRate,
        'Average Mortgage Type': API.getAvgMortgageType,
        'Leads': API.getLeadsData,
        'Lead Conversion Ratio': API.getLeadConversionData,
        'Abandoned Loan Rate': API.getLoanAbandonedRate,
        'Average Loan Value': API.getAvgLoanValue,
        'Sample': API.getSampleData,
        'Average Life Cycle of Mortgage Loans': API.getAvgLifeCycle,
        'Average Number of Conditions per Loan': API.getTaskStatus,
    };

    // Function to fetch data based on activeComponent
    // const fetchReportData = () => {
    //     const apiPath = apiMapping[activeComponent];
    //     if (apiPath) {
    //         getApiCallDynamic({ path: apiPath, param })
    //             .then((res) => {
    //                 if (res?.status === 200) {
    //                     setAdditionalProps(res.data);
    //                     // console.log(
    //                     //     `${activeComponent} data fetched and stored:`,
    //                     //     JSON.stringify(res.data, null, 2)
    //                     // );
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.error(`Error fetching data for ${activeComponent}:`, err);
    //             });
    //     } else {
    //         console.warn(`No API mapping found for activeComponent: ${activeComponent}`);
    //     }
    // };


    const fetchReportData = () => {
        const apiPath = apiMapping[activeComponent];
        return new Promise((resolve, reject) => {
            if (apiPath) {
                getApiCallDynamic({ path: apiPath, param })
                    .then((res) => {
                        if (res?.status === 200) {
                            setAdditionalProps(res.data);
                            // console.log(`${activeComponent} data fetched and stored:`, JSON.stringify(res.data, null, 2));
                            resolve(); // Resolve when data is successfully fetched
                        } else {
                            reject('Failed to fetch data');
                        }
                    })
                    .catch((err) => {
                        console.error(`Error fetching data for ${activeComponent}:`, err);
                        reject(err); // Reject on error
                    });
            } else {
                //  console.warn(`No API mapping found for activeComponent: ${activeComponent}`);
                reject('No API mapping');
            }
        });
    };

    // Fetch data immediately based on the current activeComponent
    // fetchReportData();
    // console.warn(`additionalProps` + JSON.stringify(additionalProps, null, 2));
    const [MloList, setMloList] = useState([]);
    const [MlpList, setMlpList] = useState([]);
    const [BrokerList, setBrokerList] = useState([]);
    const [MLoOwnerList, setMLoOwnerList] = useState([]);



    // Ensure fetchReportData runs when activeComponent changes
    useEffect(() => {
        fetchReportData();
    }, [activeComponent]);

    const [brokerProfileCode, setBrokerProfileCode] = useState(null)

    useEffect(() => {

        if (!accessFromAdmin) {
            getApiCallDynamic({ path: API.teams, param: { role: "broker" } }).then(
                (res) => {
                    if (res?.status === 200) {
                        const brokerListFinal = res.data.data.map((item) => ({
                            name: `${item.firstName} ${item.lastName}`,
                            role: item.role,
                            userCode: item.userCode,
                            stateCodes: [...new Set(item.stateStatus.map((state) => state.stateCode))],
                        }));
                        setBrokerList(brokerListFinal);
                        setBrokerProfileCode(brokerListFinal[0])
                    }
                }


            );


            getApiCallDynamic({ path: API.teams, param: { role: "mlo" } }).then(
                (res) => {
                    if (res?.status === 200) {
                        const mloListFinal = res.data.data.map((item) => ({
                            name: `${item.firstName} ${item.lastName}`,
                            role: item.role,
                            userCode: item.userCode,
                            stateCodes: [...new Set(item.stateStatus.map((state) => state.stateCode))],
                        }));
                        setMloList(mloListFinal);
                    }
                }
            );
            getApiCallDynamic({ path: API.teams, param: { role: "mlp" } }).then(
                (res) => {
                    if (res?.status === 200) {

                        const mlpListFinal = res.data.data.map((item) => ({
                            name: `${item.firstName} ${item.lastName}`,
                            role: item.role,
                            userCode: item.userCode,
                            stateCodes: [...new Set(item.stateStatus.map((state) => state.stateCode))],
                        }));

                        setMlpList(mlpListFinal);

                    }
                }
            );

        }


        // getApiCallDynamic({ path: API.teams, param: { role: "mlo owner" } }).then(
        //   (res) => {
        //     if (res?.status === 200 && res.data && res.data.data) {
        //       setMLoOwnerList(res.data.data);
        //     }

        //   }


        // );

    }, []);



    // console.log(" MLO Final " + JSON.stringify(MloList, null, 2))
    // console.log(" MLP Final " + JSON.stringify(MlpList, null, 2))
    console.log(" Broker Final " + JSON.stringify(brokerProfileCode, null, 2))

    // Combine all lists into a single array with displayName
    const combinedList = [
        ...MloList.map(item => ({
            ...item,
            displayName: `${item.name} (${item.role})`,
        })),
        ...MlpList.map(item => ({
            ...item,
            displayName: `${item.name} (${item.role})`,
        })),
        ...BrokerList.map(item => ({
            ...item,
            displayName: `${item.name} (${item.role})`,
        })),
    ];

    const combinedListForLeads = [
        ...MloList.map(item => ({
            ...item,
            displayName: `${item.name} (${item.role})`,
        })),

        ...BrokerList.map(item => ({
            ...item,
            displayName: `${item.name} (${item.role})`,
        })),
    ];


    const [isApplied, setIsApplied] = useState(false);
    const [dateSelection, setDateSelection] = useState({ type: '', selectedDate: null, startDate: null, endDate: null });
    const [filteredData, setFilteredData] = useState([]);
    const [loanStateId, setLoanStateId] = useState('All'); // Default to "All"
    const [brokerCode, setBrokerCode] = useState('All'); // Default to "All"
    const [mloCode, setMloCode] = useState('All'); // Default to "All"
    const [mlpCode, setMlpCode] = useState('All'); // Default to "All"
    const [loanCategory, setLoanCategory] = useState('All'); // Default to "All"
    const [loanTerm, setLoanTerm] = useState('All'); // Default to "All"
    const [loanMonthYear, setLoanMonthYear] = useState('All'); // Default to "All"


    const [selectedUserCode, setSelectedUserCode] = useState('All');
    const [selectedStateCode, setSelectedStateCode] = useState('All');
    const [selectedMonth, setSelectedMonth] = useState('All');
    const [selectedYear, setSelectedYear] = useState('All');


    const [relativeYearRange, setRelativeYearRange] = useState('All'); // For selected year range (e.g., Last 1 year, 2 years)
    const [yearsRange, setYearsRange] = useState([]); // To store the calculated years
    const currentYear = new Date().getFullYear(); // Get the current year




    const handleDateChange = useCallback(({ type, selectedDate, startDate, endDate }) => {
        setDateSelection({ type, selectedDate, startDate, endDate });
        console.log(" selectedDate " + JSON.stringify(selectedDate, null, 2))
        console.log(" startDate " + JSON.stringify(startDate, null, 2))
        console.log(" endDate " + JSON.stringify(endDate, null, 2))
    }, []);




    const handleUserCodeChange = (event) => {
        setSelectedUserCode(event.target.value);
        //  console.log(" Clicked " + event.target.value)
    };

    const handleStateCodeChange = (event) => {
        setSelectedStateCode(event.target.value);
        //  console.log(" Clicked state " + event.target.value)
    };


    const handleLoanCategoryChange = (event) => {
        setLoanCategory(event.target.value);
        //  console.log(" Clicked Category " + event.target.value)
    };

    const handleLoanTermChange = (event) => {
        setLoanTerm(event.target.value);
        //  console.log(" Clicked Category " + event.target.value)
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
        //  console.log(" Clicked Month " + event.target.value)
    };


    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
        //  console.log(" Clicked Year " + event.target.value)
    };



    const handleRelativeYearRangeChange = (event) => {
        const selectedRange = event.target.value;
        setRelativeYearRange(selectedRange);

        const rangeYears = [];

        // For "Current Year"
        if (selectedRange === 'Current') {
            rangeYears.push(currentYear);  // Only current year
        }
        // For "Last N Years"
        else if (selectedRange !== 'All') {
            for (let i = 0; i < selectedRange; i++) {
                rangeYears.push(currentYear - i - 1);  // For last 1, 2, 3 years, etc.
            }
        }

        setYearsRange(rangeYears);  // Set the calculated years to state
        //  console.log("Clicked last n Year: ", rangeYears);
    };



    const handleLoanMonthYearChange = (event) => {
        setLoanMonthYear(event.target.value);
    };


    let filtered = additionalProps || [];

    let profileInfo = useRecoilValue(profileState);
    console.log("brokerProfileCode -- " + JSON.stringify(brokerProfileCode, null, 2))



    const handleApply = async () => {


        await fetchReportData();


        // if (selectedUserCode !== "All") {
        //     filtered = filtered.filter((item) =>
        //         [item.brokerCode, item.mloCode, item.mlpCode].includes(selectedUserCode)
        //     );
        // }

        filtered = filtered.filter((item) =>
            [item.brokerCode].includes(brokerProfileCode.userCode))

        if (accessFromAdmin === true) {
            if (selectedUserFromAdmin !== "All") {
                filtered = filtered.filter((item) =>
                    [item.brokerCode, item.mloCode, item.mlpCode].includes(selectedUserFromAdmin)
                );
            }
        } else {
            if (selectedUserCode !== "All") {
                filtered = filtered.filter((item) =>
                    [item.brokerCode, item.mloCode, item.mlpCode].includes(selectedUserCode)
                );
            }
        }
        console.log(" accessFromAdmin" + JSON.stringify(accessFromAdmin, null, 2))
        console.log(" filtered" + JSON.stringify(filtered, null, 2))



        // Filter by value1 (head), skip if "All" is selected
        if (loanStateId !== 'All') {
            filtered = filtered.filter((item) => item.loanState === loanStateId);
        }

        // Filter by value1 (head), skip if "All" is selected
        if (selectedStateCode !== 'All') {
            filtered = filtered.filter((item) => item.loanState === selectedStateCode);
        }


        if (loanCategory !== 'All') {
            filtered = filtered.filter((item) => item.loanCategory === loanCategory);
        }

        if (loanTerm !== 'All') {
            filtered = filtered.filter((item) => item.head === loanTerm);
        }


        if (selectedMonth !== 'All') {
            filtered = filtered.filter((item) => item.loanMonth === selectedMonth);
        }


        if (selectedYear !== 'All') {
            filtered = filtered.filter((item) => String(item.loanYear) === String(selectedYear));
        }

        // Filter the data based on the selected years range

        if (relativeYearRange !== "All") {
            filtered = filtered.filter((item) =>
                yearsRange.includes(Number(item.loanYear))
            );
        }



        // Filter by single date
        // if (dateSelection.type === 'singleDate' && dateSelection.selectedDate) {
        //     const selectedDateJS = dateSelection.selectedDate.toDate();
        //     console.log(" R Date " + JSON.stringify(selectedDateJS, null, 2));

        //     // Filter based on loanMonth and loanYear
        //     filtered = filtered.filter((item) => {
        //         const itemDate = new Date(item.loanYear, item.loanMonth - 1); // month is zero-indexed
        //         return itemDate.toDateString() === selectedDateJS.toDateString();
        //     });
        // }



        if (dateSelection.type === 'singleDate' && dateSelection.selectedDate) {
            const selectedDateJS = dateSelection.selectedDate.toDate();
            const selectedMonth = selectedDateJS.getMonth() + 1; // Month is 0-indexed, so add 1
            const selectedYear = selectedDateJS.getFullYear();

            console.log("Selected Month:", selectedMonth, "Selected Year:", selectedYear);

            // Filter based on loanMonth and loanYear
            filtered = filtered.filter((item) => {
                const itemMonth = Number(item.loanMonth); // Convert loanMonth to a number
                const itemYear = Number(item.loanYear);   // Convert loanYear to a number
                return itemYear === selectedYear && itemMonth === selectedMonth;
            });

            console.log("Filtered Data:", filtered);
        }


        // Filter by date range
        else if (dateSelection.type === 'dateRange' && dateSelection.startDate && dateSelection.endDate) {
            // const startDateJS = dateSelection.startDate.toDate();
            // const endDateJS = dateSelection.endDate.toDate();

            // //   console.log(" R Start date " + startDateJS + " R End date " + endDateJS);

            // // Filter based on loanMonth and loanYear within the date range
            // filtered = filtered.filter((item) => {
            //     const itemDate = new Date(item.loanYear, item.loanMonth - 1); // month is zero-indexed
            //     return itemDate >= startDateJS && itemDate <= endDateJS;
            // });

            if (dateSelection.type === 'dateRange' && dateSelection.startDate && dateSelection.endDate) {
                const startDateJS = dateSelection.startDate.toDate();
                const endDateJS = dateSelection.endDate.toDate();

                // Check if activeComponent is 'Average Life Cycle of Mortgage Loans'
                if (activeComponent === 'Average Life Cycle of Mortgage Loans') {
                    // Filter based on loanStartDate and loanProcessedDate within the date range
                    filtered = filtered.filter((item) => {
                        const loanStartDate = new Date(item.loanStartDate);
                        const loanProcessedDate = new Date(item.loanProcessedDate);

                        // Check if either loanStartDate or loanProcessedDate falls within the range
                        return (
                            (loanStartDate >= startDateJS && loanStartDate <= endDateJS) ||
                            (loanProcessedDate >= startDateJS && loanProcessedDate <= endDateJS)
                        );
                    });
                } else {
                    // Default behavior: filter based on loanYear and loanMonth
                    filtered = filtered.filter((item) => {
                        const itemDate = new Date(item.loanYear, item.loanMonth - 1); // month is zero-indexed
                        return itemDate >= startDateJS && itemDate <= endDateJS;
                    });
                }
            }

        }


        // console.log('Applying filters:', JSON.stringify(filtered, null, 2));
        setFilteredData(filtered);
        setIsApplied(true);

    };
    // Use useEffect to trigger chart rendering once filteredData is updated
    useEffect(() => {
        if (isApplied && filteredData.length > 0) {
            console.log('Data updated and ready for chart rendering:', filteredData);
            // The chart rendering logic here
            renderChart(filteredData);
        }
    }, [filteredData, isApplied, additionalProps]); // Trigger when filteredData or isApplied changes

    const renderChart = () => {
        // Use `filteredData` if filters are applied; otherwise, use the original data
        const dataToUse = isApplied ? filteredData : additionalProps;

        switch (activeComponent) {
            case 'Monthly Loan Product Analysis':
                return <CustomStackedBarChart data={dataToUse} />;
            // case 'Monthly Loan Product Analysis':
            //     return <CustomGroupedMultipleBarChart data={dataToUse} />;
            case 'Sample':
                return <Chart1 data={dataToUse} />;
            case 'Loan Term':
                return <Chart3 data={dataToUse} />;
            case 'Loan Category':
                return <Chart3 data={dataToUse} />;
            case 'Chart5':
                return <CustomBarChart data={dataToUse} />;
            case 'Application Approval Rate':
                return <CustomGroupedBarChart


                    data={dataToUse}
                    xAxisLabel=""
                    yAxisLeftLabel="# of Loans"
                    yAxisRightLabel="Rate (%)"
                    bar1Key="submitted"
                    bar1Color="#82ca9d"
                    bar1Name="# of Application Submitted"
                    bar2Key="approved"
                    bar2Color="#8884d8"
                    bar2Name="# of Application Approved"
                    lineKey="approvalRate"
                    lineColor="#ff7300"
                    lineName="Application Approval Rate (%)"

                />;
            case 'Mortgage Loan Declined Rate':
                return (
                    <CustomGroupedBarChart
                        data={dataToUse}
                        // barKeys={{ submitted: 'No of loan received', approved: 'No of declined loans' }}
                        // lineKey="Declined rate"
                        // colors={{ bar2: '#FF0000', bar1: '#82ca9d', line: '#7B68EE' }}


                        xAxisLabel=""
                        yAxisLeftLabel="# of Loans"
                        yAxisRightLabel="Rate (%)"
                        bar1Key="loanRecevied"
                        bar1Color="#82ca9d"
                        bar1Name="# of Loans Recevied"
                        bar2Key="declinedLoan"
                        bar2Color="#8884d8"
                        bar2Name="# of Declined Loans"
                        lineKey="declinedRate"
                        lineColor="#ff7300"
                        lineName="Declined Rate (%)"
                    />
                );
            case 'Incomplete Loan Application Rate':
                return (
                    <CustomGroupedBarChart
                        data={dataToUse}
                        // barKeys={{ submitted: 'No of application received', approved: 'No of application due to incompleteness' }}
                        // lineKey="Incomplete loan application rate"
                        // colors={{ bar2: '#FF0000', bar1: '#82ca9d', line: '#7B68EE' }}


                        xAxisLabel=""
                        yAxisLeftLabel="# of Loans"
                        yAxisRightLabel="Rate (%)"
                        bar1Key="received"
                        bar1Color="#82ca9d"
                        bar1Name="# of Application Recevied"
                        bar2Key="incomplete"
                        bar2Color="#8884d8"
                        bar2Name="# of Application Incomplete"
                        lineKey="incompleteApplicationRate"
                        lineColor="#ff7300"
                        lineName="Incomplete Loan Application Rate (%)"
                    />
                );
            case 'Average Mortgage Type':
                return (
                    <CustomGroupedBarType
                        data={dataToUse}
                        barKeys={{ submitted: 'Loan Type' }}
                        lineKey="% of Applications"
                        colors={{ bar2: '#FF0000', bar1: '#82ca9d', line: '#ff7300' }}
                        activeComponent={activeComponent}

                    // xAxisLabel=""
                    // yAxisLeftLabel="# of Loans"
                    // yAxisRightLabel="Rate (%)"
                    // bar1Key="approvedWithConditions"
                    // bar1Color="#82ca9d"
                    // bar1Name="# of Loans Approved with Conditions"
                    // bar2Key="notFunded"
                    // bar2Color="#8884d8"
                    // bar2Name="# of Loans Not Funded"
                    // lineKey="abandonedRate"
                    // lineColor="#ff7300"
                    // lineName="Abandoned Loan Rate (%)"
                    />
                );
            // case 'Lead Conversion Ratio':
            //     return (
            //         <CustomGroupedBarChart
            //             data={dataToUse}
            //             // barKeys={{ submitted: 'No of prospect application', approved: 'No of application submitted' }}
            //             // lineKey="Lead conversion rate"
            //             // colors={{ bar2: '#FF0000', bar1: '#82ca9d', line: '#7B68EE' }}

            //             xAxisLabel=""
            //             yAxisLeftLabel="# of Loans"
            //             yAxisRightLabel="Rate (%)"
            //             bar1Key="No of prospect Application"
            //             bar1Color="#82ca9d"
            //             bar1Name="No of prospect application"
            //             bar2Key="No of application Converted to Loan"
            //             bar2Color="#8884d8"
            //             bar2Name="No of application submitted"
            //             lineKey="Lead conversion rate"
            //             lineColor="#ff7300"
            //             lineName="Lead conversion rate"
            //             activeComponent={activeComponent}


            //         />
            //     );
            case 'Lead Conversion Ratio':
                return (
                    <CustomGroupedBarChartForLeads
                        data={dataToUse}
                        // barKeys={{ submitted: 'No of prospect application', approved: 'No of application submitted' }}
                        // lineKey="Lead conversion rate"
                        // colors={{ bar2: '#FF0000', bar1: '#82ca9d', line: '#7B68EE' }}

                        xAxisLabel=""
                        yAxisLeftLabel="# of Loans"
                        yAxisRightLabel="% of Loans from Leads"
                        bar1Key="No of prospect Application"
                        bar1Color="#82ca9d"
                        bar1Name="# of Loans from Leads"
                        bar2Key="No of application Converted to Loan"
                        bar2Color="#8884d8"
                        bar2Name="# of Total Loans"
                        lineKey="Lead conversion rate"
                        lineColor="#ff7300"
                        lineName="% of Loans from Leads"
                        activeComponent={activeComponent}


                    />
                );
            case 'Abandoned Loan Rate':
                return (
                    <CustomGroupedBarChart
                        data={dataToUse}
                        xAxisLabel=""
                        yAxisLeftLabel="# of Loans"
                        yAxisRightLabel="Rate (%)"
                        bar1Key="approvedWithConditions"
                        bar1Color="#82ca9d"
                        bar1Name="# of Loans Approved with Conditions"
                        bar2Key="notFunded"
                        bar2Color="#8884d8"
                        bar2Name="# of Loans Not Funded"
                        lineKey="abandonedRate"
                        lineColor="#ff7300"
                        lineName="Abandoned Loan Rate"
                    />

                );
            case 'Average Loan Value':
                return (
                    <CustomGroupedBarChartWithFilter
                        data={dataToUse}
                    // barKeys={{ submitted: 'No of loan approved with condition', approved: 'Loan not funded' }}
                    // lineKey="Abandoned loan rate"
                    // colors={{ bar2: '#FF0000', bar1: '#82ca9d', line: '#7B68EE' }}
                    />
                );
            case 'Leads':
                return (
                    <CustomBarChart
                        data={dataToUse}
                    // barKeys={{ submitted: 'No of loan approved with condition', approved: 'Loan not funded' }}
                    // lineKey="Abandoned loan rate"
                    // colors={{ bar2: '#FF0000', bar1: '#82ca9d', line: '#7B68EE' }}
                    />
                );
            case 'Loan Category/Loan Term':
                return (
                    <LoanTermBar
                        data={dataToUse}
                    // barKeys={{ submitted: 'No of loan approved with condition', approved: 'Loan not funded' }}
                    // lineKey="Abandoned loan rate"
                    // colors={{ bar2: '#FF0000', bar1: '#82ca9d', line: '#7B68EE' }}
                    />
                );
            case 'Loan Term / Loan Category':
                return (
                    <LoanCategoryBar
                        data={dataToUse}
                    // barKeys={{ submitted: 'No of loan approved with condition', approved: 'Loan not funded' }}
                    // lineKey="Abandoned loan rate"
                    // colors={{ bar2: '#FF0000', bar1: '#82ca9d', line: '#7B68EE' }}
                    />
                );
            case 'Average Life Cycle of Mortgage Loans':
                return (
                    <AvgLoanCycle
                        data={dataToUse}
                    // barKeys={{ submitted: 'No of loan approved with condition', approved: 'Loan not funded' }}
                    // lineKey="Abandoned loan rate"
                    // colors={{ bar2: '#FF0000', bar1: '#82ca9d', line: '#7B68EE' }}
                    />
                );
            case 'Average Number of Conditions per Loan':
                return (
                    <TaskChart
                        data={dataToUse}
                    // barKeys={{ submitted: 'No of loan approved with condition', approved: 'Loan not funded' }}
                    // lineKey="Abandoned loan rate"
                    // colors={{ bar2: '#FF0000', bar1: '#82ca9d', line: '#7B68EE' }}
                    />
                );

            default:
                return <div>No chart found</div>;
        }
    };

    // Create a list of all state codes for filtering (for matching purposes)
    const stateCodes = CONTACT_STATE.map((state) => state.value);
    const uniqueStateCodes = [
        'All', // Including "All" option for selection
        ...new Set(
            BrokerList.flatMap((broker) => broker.stateCodes)
        ),
    ];

    // Collect eligible state codes from BrokerList
    const eligibleStateCodes = [
        ...new Set(
            BrokerList.flatMap((broker) => broker.stateCodes)
        )
    ];



    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
                <Box display="flex" alignItems="center" mb={2}>
                    <IconButton onClick={onBack} aria-label="back">
                        <KSBackArrowIcon />
                    </IconButton>
                    <Typography variant="h6" component="h2" ml={1}>
                        {activeComponent}
                    </Typography>
                </Box>

                <Grid container spacing={2} marginBottom={3}>



                    {activeComponent !== 'Leads' && activeComponent !== 'Lead Conversion Ratio' && (
                        <Grid item md={3} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="state-select-label">Select State</InputLabel>
                                <Select
                                    labelId="state-select-label"
                                    value={selectedStateCode}
                                    onChange={handleStateCodeChange}
                                    label="Select State"
                                >
                                    <MenuItem value="All">All</MenuItem>

                                    {/* Iterate over the unique eligible states */}
                                    {eligibleStateCodes.map((stateCode) => {
                                        const state = CONTACT_STATE.find(state => state.value === stateCode);  // Find matching state in CONTACT_STATE
                                        return (
                                            state && (
                                                <MenuItem key={stateCode} value={stateCode}>
                                                    {state.label} {/* Show user-friendly label */}
                                                </MenuItem>
                                            )
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {/* Dropdown for Combined List */}

                    {!accessFromAdmin &&
                        <Grid item md={3} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="user-select-label">Select User</InputLabel>
                                <Select
                                    labelId="user-select-label"
                                    value={selectedUserCode}
                                    onChange={handleUserCodeChange}
                                    label="Select User"
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    {/* {combinedList.map((item, index) => ( */}
                                    {(activeComponent === 'Leads' ? combinedListForLeads : combinedList).map((item, index) => (
                                        <MenuItem key={index} value={item.userCode}>
                                            {item.displayName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    }






                    {activeComponent !== 'Leads' && activeComponent !== 'Lead Conversion Ratio' && (
                        <Grid item md={2} xs={12}>

                            <FormControl fullWidth>
                                <InputLabel id="value2-select-label">Loan Category</InputLabel>
                                <Select
                                    labelId="value2-select-label"
                                    value={loanCategory}
                                    onChange={handleLoanCategoryChange}
                                    label="Loan Category"
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    {TYPES_OF_LOAN.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                    )}

                    {(activeComponent === 'Loan Term / Loan Category' || activeComponent === 'Loan Category/Loan Term') && (
                        <Grid item md={2} xs={12}>

                            <FormControl fullWidth>
                                <InputLabel id="value2-select-label">Loan Term</InputLabel>
                                <Select
                                    labelId="value2-select-label"
                                    value={loanTerm}
                                    onChange={handleLoanTermChange}
                                    label="Loan Term"
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    {TERM_OF_LOAN.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                    )}



                    {/* Month Dropdown */}
                    {activeComponent !== 'Average Life Cycle of Mortgage Loans' && (
                        <Grid item md={2} xs={6}>


                            <FormControl fullWidth>
                                <InputLabel id="month-select-label">Month</InputLabel>
                                <Select
                                    labelId="month-select-label"
                                    value={selectedMonth}
                                    onChange={handleMonthChange}
                                    label="Month"
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    {[
                                        { number: "01", name: "January" },
                                        { number: "02", name: "February" },
                                        { number: "03", name: "March" },
                                        { number: "04", name: "April" },
                                        { number: "05", name: "May" },
                                        { number: "06", name: "June" },
                                        { number: "07", name: "July" },
                                        { number: "08", name: "August" },
                                        { number: "09", name: "September" },
                                        { number: "10", name: "October" },
                                        { number: "11", name: "November" },
                                        { number: "12", name: "December" }
                                    ].map((month, index) => (
                                        <MenuItem key={index} value={month.number}>
                                            {month.number} - {month.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>

                    )}


                    {/* Year Dropdown */}
                    {activeComponent !== 'Average Life Cycle of Mortgage Loans' && (
                        <Grid item md={2} xs={6}>

                            <FormControl fullWidth>
                                <InputLabel id="year-select-label">Year</InputLabel>
                                <Select
                                    labelId="year-select-label"
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                    label="Year"
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((year, index) => (
                                        <MenuItem key={index} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {activeComponent !== 'Leads' && activeComponent !== 'Lead Conversion Ratio' && activeComponent !== 'Average Life Cycle of Mortgage Loans' && (
                        <Grid item md={2} xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="relative-year-select-label">Last N Years</InputLabel>
                                <Select
                                    labelId="relative-year-select-label"
                                    value={relativeYearRange}
                                    onChange={handleRelativeYearRangeChange}
                                    label="Last N Years"
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="Current">Current Year</MenuItem>  {/* Added Current Year option */}
                                    {[1, 2, 3, 4, 5].map((range, index) => (
                                        <MenuItem key={index} value={range}>
                                            Last {range} Year{range > 1 ? 's' : ''}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                    )}

                </Grid>

                <CustomDateRangePicker onDateChange={handleDateChange} />

                <Button variant="contained" onClick={handleApply} sx={{ mb: 3 }}>
                    Apply
                </Button>

                {/* {renderChart()} */}
                {isApplied && filteredData.length > 0 && (
                    <div>
                        {renderChart(filteredData)}
                    </div>
                )}
            </Box>
        </LocalizationProvider >
    );
};
