
// // // // import React from 'react';
// // // // import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// // // // const CustomStackedBarChart = ({ onBack, data }) => {


// // // //     // const formatValue = (value) => {
// // // //     //     if (value >= 100000) {
// // // //     //         return `${(value / 100000).toFixed(2)}L`;
// // // //     //     } else if (value >= 1000) {
// // // //     //         return `${(value / 1000).toFixed(2)}K`;
// // // //     //     } else {
// // // //     //         return value.toString();
// // // //     //     }
// // // //     // };

// // // //     const formatValue = (value) => {
// // // //         if (value >= 1000) {
// // // //             return `${(value / 1000).toFixed(1)}K`; // Only use "K" for values >= 1000 and round to 1 decimal place
// // // //         } else {
// // // //             return value.toString(); // No "K" for values < 1000
// // // //         }
// // // //     };


// // // //     const formattedData = data.map(item => ({
// // // //         ...item,
// // // //         value2: formatValue(item.value2)
// // // //     }));

// // // //     // Custom tooltip formatter
// // // //     const customTooltip = ({ active, payload }) => {
// // // //         if (active && payload && payload.length) {
// // // //             return (
// // // //                 <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
// // // //                     <p><strong>{payload[0].payload.head}</strong></p>
// // // //                     <p>No of Loans: {payload[0].value}</p>
// // // //                     <p>Loan Amount: {payload[1].value}</p>
// // // //                 </div>
// // // //             );
// // // //         }
// // // //         return null;
// // // //     };

// // // //     // Custom legend formatter
// // // //     const customLegend = (value) => {
// // // //         return value === 'value1' ? 'No of Loans' : 'Loan Amount';
// // // //     };

// // // //     return (
// // // //         <ResponsiveContainer width="100%" height={400}>
// // // //             <BarChart
// // // //                 data={formattedData}
// // // //                 margin={{
// // // //                     top: 20,
// // // //                     right: 30,
// // // //                     left: 20,
// // // //                     bottom: 5,
// // // //                 }}
// // // //                 barSize={40}
// // // //             >
// // // //                 <CartesianGrid strokeDasharray="3 3" />
// // // //                 <XAxis dataKey="head" />
// // // //                 <YAxis />
// // // //                 <Tooltip content={customTooltip} />
// // // //                 <Legend formatter={customLegend} />
// // // //                 <Bar dataKey="value1" stackId="a" fill="#8884d8" />
// // // //                 <Bar dataKey="value2" stackId="a" fill="#82ca9d" />
// // // //             </BarChart>
// // // //         </ResponsiveContainer>
// // // //     );
// // // // };

// // // // export default CustomStackedBarChart;

import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    Label,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
} from 'recharts';

const CustomStackedBarChart = ({ onBack, data }) => {
    // Format the value as needed
    const formatValue = (value) => {
        if (value >= 1000) {
            return `${(value / 1000).toFixed(1)}K`; // Only use "K" for values >= 1000
        } else {
            return value.toString(); // No "K" for values < 1000
        }
    };

    const formattedData = data.map((item) => ({
        ...item,
        value2: item.value2 >= 1000 ? (item.value2 / 1000).toFixed(1) : item.value2, // Format value2 to thousands
    }));

    // Custom tooltip formatter
    const customTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
                    <p>
                        <strong>{payload[0].payload.head}</strong>
                    </p>
                    <p># of Loans: {payload[0].value}</p>
                    <p>Loan Amount: $ {payload[1].value}K</p> {/* Display loan amount in thousands */}
                </div>
            );
        }
        return null;
    };

    // Custom legend formatter
    const customLegend = (value) => {
        return value === 'value1' ? '# of Loans' : 'Loan Amount';
    };

    // Right Y-Axis formatter to show values in thousands
    const rightAxisFormatter = (value) => {
        if (value >= 1000) {
            return `${(value / 1000).toFixed(1)}K`;
        }
        return value;
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                data={formattedData}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                barSize={25} // Fixed bar width
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="head" />

                {/* Left Y-Axis for No of Loans */}
                <YAxis yAxisId="left">
                    <Label
                        value="# of Loans"
                        angle={-90}
                        position="left"
                        style={{ textAnchor: 'middle', fontWeight: 'bold' }}
                    />
                </YAxis>

                {/* Right Y-Axis for Loan Amount */}
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    tickFormatter={rightAxisFormatter} // Format Y-Axis ticks
                >
                    <Label
                        value="Loan Amount in $"
                        angle={90}
                        position="right"
                        style={{ textAnchor: 'middle', fontWeight: 'bold' }}
                    />
                </YAxis>

                <Tooltip content={customTooltip} />
                <Legend formatter={customLegend} />

                {/* Bar for No of Loans */}
                <Bar dataKey="value1" stackId="a" fill="#8884d8" yAxisId="left">
                    <LabelList dataKey="value1" position="top" />
                </Bar>

                {/* Bar for Loan Amount */}
                {/* <Bar dataKey="value2" stackId="a" fill="#82ca9d" yAxisId="right">
                    <LabelList dataKey="value2" position="top" />
                </Bar> */}
                <Bar dataKey="value2" stackId="a" fill="#82ca9d" yAxisId="right">
                    <LabelList
                        dataKey="value2"
                        position="top"
                        formatter={(value) => `${value}K`} // Append "K" to the label
                    />
                </Bar>


            </BarChart>
        </ResponsiveContainer>
    );
};

export default CustomStackedBarChart;
